<template>
  <v-app>
    <v-main class="bg-light">
      <v-container class="py-10">
        <v-row>
          <v-col md="auto">
            <v-card elevation="2" outlined>
              <v-card-text class="p-5">
                <table class="col-12 p-0">
                  <tr>
                    <td class="pe-3">
                      <div class="text-h5">Пользователей</div>
                    </td>
                    <td>
                      <div v-if="userCount" class="text-h4 text-end">
                        {{ userCount.all }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-divider class="my-3" />
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-3">
                      <div class="text-h5">Репетиторов</div>
                    </td>
                    <td>
                      <div v-if="userCount" class="text-h4 text-end">
                        {{ userCount.tutor }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-divider class="my-3" />
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-3">
                      <div class="text-h5">Студентов</div>
                    </td>
                    <td>
                      <div v-if="userCount" class="text-h4 text-end">
                        {{ userCount.student }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-divider class="my-3" />
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-3">
                      <div class="text-h5">Тех. поддержка</div>
                    </td>
                    <td>
                      <div v-if="userCount" class="text-h4 text-end">
                        {{ userCount.support }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <v-divider class="my-3" />
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-3">
                      <div class="text-h5">Администраторов</div>
                    </td>
                    <td>
                      <div v-if="userCount" class="text-h4 text-end">
                        {{ userCount.admin }}
                      </div>
                    </td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Echo from "laravel-echo";
import api from "../../api";
window.pusher = require("pusher-js");

export default {
  name: "PublicDashboard",
  data() {
    return {
      userCount: null,
      token: null,
    };
  },
  mounted() {
    this.initStatic();
  },
  methods: {
    async getUserCount() {
      try {
        const req = await api.publicDashboard.userCount();
        this.userCount = req.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getToken() {
      try {
        const res = await api.settings.accessIp.tokenIp();
        this.token = res.data.access_token;
      } catch (e) {
        console.error(e);
        this.$router.push({ name: "Home" });
      }
    },
    async initStatic() {
      await this.getUserCount();
      await this.getToken();
      console.log(
        this.token,
        process.env.VUE_APP_PUSHER_KEY,
        process.env.VUE_APP_URL_DASHBOARD
      );
      const echo = new Echo({
        broadcaster: "pusher",
        key: `${process.env.VUE_APP_PUSHER_KEY}`,
        wsHost: `${process.env.VUE_APP_URL_DASHBOARD}`,
        wsPort: 6004,
        wssPort: 6004,
        disableStats: true,
        encrypted: false,
        forceTLS: false,
        enabledTransports: ["ws"],
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      });
      echo.channel(`all-users`).listen("AllRegisteredUsersCount", (data) => {
        this.userCount = data;
      });
      echo
        .join(`active-users`)
        .here((users) => {
          console.log(users);
        })
        .joining((user) => {
          console.log(user);
        })
        .leaving((user) => {
          console.log(user);
        })
        .error((error) => {
          console.error(error);
        });
    },
  },
};
</script>
